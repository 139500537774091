import * as React from 'react';

import { Link, graphql } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import MailChimp from '../../components/mailchimp';

const Categories = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <Layout>
    <SEO
      title="Expert articles, blogs, and tutorials on Web Components, JavaScript, WordPress, and everything Web"
      desc="Learn something new everyday! Expert knowledge for the Web Community from the Nieuwenhuis Web Development team."
      pathname="/blog/categories"
    />
    <section>
      <div className="text-center mb-6">
        <h1>Categories</h1>
        <p>Learn something new everyday</p>
      </div>
    </section>
    <section>
      <ul className="mt-6 m-auto max-w-screen-md flex flex-col">
        {group.map(category => (
          <li key={category.fieldValue} className="text-center my-2">
            <Link
              to={`/blog/category/${category.fieldValue
                .toLowerCase()
                .replace(/\s/g, `-`)}`}
            >
              {category.fieldValue} ({category.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </section>
    <MailChimp />
  </Layout>
);

export default Categories;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`;
